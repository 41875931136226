<template>
  <div v-if="currentOrderItem">
    <div class="row mb-0">
      <div v-if="showName" id="order-item-name" class="col-md-12 mb-4 text-center">
        {{ currentOrderItem.name }}
      </div>

      <div v-if="showCompany" class="col-md-6 mb-2">
        <h6>
          Company:
        </h6>
        <p>
          {{ currentOrderItem.company.name }}
        </p>
      </div>

      <div v-for="(item, index) in nonBooleanOrderItemData" :key="item.id" style="display:contents">
        <div
          v-if="index.indexOf('official') === -1 && index !== 'contact_person' && index.indexOf('address') === -1 && typeof (item) != 'object'"
          class="col-md-6 mb-4"
        >
          <h6>
            {{ displayKey(index) }}:
          </h6>
          <p>
            {{ item }}
          </p>
        </div>

        <div v-else-if="index.indexOf('official') === 0 || index === 'contact_person'" style="display:contents">
          <div v-if="Array.isArray(item)" class="col-md-6 mb-4">
            <h6>
              {{ item[0].role ? (item[0].role || 'Official') + '(s)' : 'Contact(s)' }}:
            </h6>

            <div v-for="official in item" :key="official.id">
              <div v-if="isValidOfficial(official)" class="mb-1">
                <div v-if="official.first_name || official.last_name">
                  <p class="font-weight-bold">
                    {{ official.first_name }} {{ official.last_name }}
                  </p>
                </div>

                <order-item-address
                  v-if="validAddress(official.address)"
                  :address="official.address"
                  :attention="official.attention"
                />
              </div>
            </div>
          </div>

          <div v-else-if="isValidOfficial(item)" class="col-md-6 mb-4">
            <h6> {{ item.role || 'Official' }}: </h6>
            <div v-if="item.first_name || item.last_name">
              <p class="font-weight-bold">
                {{ item.first_name }} {{ item.last_name }}
              </p>
            </div>

            <order-item-address
              v-if="validAddress(item.address)"
              :address="item.address"
              :attention="item.attention"
            />
          </div>
        </div>

        <div
          v-else-if="index.indexOf('official') === -1 && index !== 'contact_person' && index.indexOf('address') > -1"
          class="col-md-6 mb-4"
        >
          <h6>
            {{ displayKey(index) }}:
          </h6>

          <order-item-address
            v-if="validAddress(item)"
            :address="item"
            :attention="item.attention"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(item, index) in booleanOrderItemData" :key="item.id" style="display:contents">
        <div class="col-md-12 mb-1">
          <div>
            <b-form-checkbox
              v-model="booleanOrderItemData[index]"
              class="float-left"
              :disabled="true"
            >
              {{ displayBooleanKey(index) }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import _ from 'lodash'
import * as helper from '@/components/SchemaForm/helper.js'
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'OrderItemData',
  components: {
    OrderItemAddress:   () => import('@/components/shared/OrderItemAddress.vue'),
  },
  props: {
    orderItemObject: {
      type: Object,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentOrderItem: null,
    }
  },
  computed: {
    ...mapGetters('schema', ['schema', 'currentObject']),
    orderItemData() {
      return helper.mergeOfficialsIntoArray(this.currentOrderItem.data.default)
    },
    nonBooleanOrderItemData() {
      return Object.fromEntries(Object.entries(this.orderItemData).filter(([_key, value]) => typeof value != 'boolean'))
    },
    booleanOrderItemData() {
      return Object.fromEntries(Object.entries(this.orderItemData).filter(([_key, value]) => typeof value == 'boolean'))
    },
    showCompany() {
      return !(this.orderItemData.hasOwnProperty('company') || this.orderItemData.hasOwnProperty('company_name'))
    },
  },
  async mounted() {
    this.currentOrderItem = await this.loadObject({
      object_type: this.orderItemObject.object_type,
      object_id: this.orderItemObject.object_id,
    })
    if (this.schema == null) await this.loadSchema()
  },
  methods: {
    ...mapActions('schema', ['loadSchema', 'loadObject']),
    displayKey(key) {
      return _.startCase(_.toLower(key))
    },
    isValidOfficial(official) {
      return (this.validAddress(official.address) && official.first_name)
    },
    validAddress(address) {
      return Object.entries(address).length
    },
    displayBooleanKey(key) {
      const title = this.schema.find(s => s.name === key).title
      return _.startCase(_.toLower(title))
    },
  },
}
</script>

<style lang="scss" scoped>
h6 {
  margin: 0 !important;
}
#order-item-name {
  font-weight: bolder;
}
</style>
