var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentOrderItem
    ? _c("div", [
        _c(
          "div",
          { staticClass: "row mb-0" },
          [
            _vm.showName
              ? _c(
                  "div",
                  {
                    staticClass: "col-md-12 mb-4 text-center",
                    attrs: { id: "order-item-name" },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.currentOrderItem.name) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showCompany
              ? _c("div", { staticClass: "col-md-6 mb-2" }, [
                  _c("h6", [_vm._v("\n        Company:\n      ")]),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.currentOrderItem.company.name) +
                        "\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._l(_vm.nonBooleanOrderItemData, function (item, index) {
              return _c(
                "div",
                { key: item.id, staticStyle: { display: "contents" } },
                [
                  index.indexOf("official") === -1 &&
                  index !== "contact_person" &&
                  index.indexOf("address") === -1 &&
                  typeof item != "object"
                    ? _c("div", { staticClass: "col-md-6 mb-4" }, [
                        _c("h6", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.displayKey(index)) +
                              ":\n        "
                          ),
                        ]),
                        _c("p", [
                          _vm._v("\n          " + _vm._s(item) + "\n        "),
                        ]),
                      ])
                    : index.indexOf("official") === 0 ||
                      index === "contact_person"
                    ? _c("div", { staticStyle: { display: "contents" } }, [
                        Array.isArray(item)
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 mb-4" },
                              [
                                _c("h6", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        item[0].role
                                          ? (item[0].role || "Official") + "(s)"
                                          : "Contact(s)"
                                      ) +
                                      ":\n          "
                                  ),
                                ]),
                                _vm._l(item, function (official) {
                                  return _c("div", { key: official.id }, [
                                    _vm.isValidOfficial(official)
                                      ? _c(
                                          "div",
                                          { staticClass: "mb-1" },
                                          [
                                            official.first_name ||
                                            official.last_name
                                              ? _c("div", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            official.first_name
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            official.last_name
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.validAddress(official.address)
                                              ? _c("order-item-address", {
                                                  attrs: {
                                                    address: official.address,
                                                    attention:
                                                      official.attention,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                }),
                              ],
                              2
                            )
                          : _vm.isValidOfficial(item)
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 mb-4" },
                              [
                                _c("h6", [
                                  _vm._v(
                                    " " + _vm._s(item.role || "Official") + ": "
                                  ),
                                ]),
                                item.first_name || item.last_name
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(item.first_name) +
                                              " " +
                                              _vm._s(item.last_name) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.validAddress(item.address)
                                  ? _c("order-item-address", {
                                      attrs: {
                                        address: item.address,
                                        attention: item.attention,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : index.indexOf("official") === -1 &&
                      index !== "contact_person" &&
                      index.indexOf("address") > -1
                    ? _c(
                        "div",
                        { staticClass: "col-md-6 mb-4" },
                        [
                          _c("h6", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.displayKey(index)) +
                                ":\n        "
                            ),
                          ]),
                          _vm.validAddress(item)
                            ? _c("order-item-address", {
                                attrs: {
                                  address: item,
                                  attention: item.attention,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.booleanOrderItemData, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticStyle: { display: "contents" } },
              [
                _c("div", { staticClass: "col-md-12 mb-1" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "float-left",
                          attrs: { disabled: true },
                          model: {
                            value: _vm.booleanOrderItemData[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.booleanOrderItemData, index, $$v)
                            },
                            expression: "booleanOrderItemData[index]",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.displayBooleanKey(index)) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }